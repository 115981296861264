$niceColor: #57606f
$yellow: #ffd70b
$lightYellow: #FFEC6C
$blue: #003a99
$nunito: 'Nunito', sans-serif
$switzer: 'Switzer', sans-serif
$lightGreen: #4db274
$green: #0b6755
$red: #f60f12
$transactionGreen: #1cab44
$lightGrey: #f1f1f1
$offWhite: #fbf9ed
$grey: #F2F2F2

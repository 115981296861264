@use 'common'

.wrapper
    width: 100%
    position: sticky
    top: 20px
    min-width: 320px
    max-width: 400px
    flex-shrink: 0
    padding: 0 20px 0 0
    @media(max-width: 768px)
        padding: 30px 20px
        top: 0
        position: static
        min-width: 280px

.inner
    background-color: common.$grey
    padding: 20px
    border-radius: 10px
    color: #000
    margin-bottom: 16px
    h1
        margin-top: 0

.emailInner
    display: flex
    margin-top: 16px
    //align-items: center
    input
        width: 100%
        color: #000
        font-size: 18px
        border: 1px solid #000
        border-radius: 3px
        padding: 10px
        font-family: common.$switzer
        margin: 0 10px 0 0
        font-size: 14px
        &:focus, &:active
            outline: none
    @media(max-width: 768px)
        flex-direction: column
        align-items: center
        input
            margin: 0
        button
            width: 100%
            margin-top: 10px

.email
    article:nth-child(1)
        font-size: 16px
        font-weight: 500
        color: #A0A0A0
        text-align: center
        margin-top: 16px
    article
        font-size: 14px
        color: #000
        font-weight: 500
        margin-top: 8px

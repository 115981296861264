@use 'common'

.headline
	font-size: 32px
	color: #000
	margin: 0 0 30px 0
	text-align: center

.wrapper
	width: 100%

.list
	list-style: none
	padding: 0
	margin: 0 auto
	max-width: 600px
	border-bottom: 2px solid #000

.item + .item
	border-top: 2px solid #000

.item
	width: 100%
	margin: 0
	font-family: common.$switzer
	padding: 10px 0
	&.active
		article
			display: block
		h3
			button
				span
					&::before
						transform: rotate(90deg) translateX(-110%)
	article
		color: #000
		margin-top: 10px
		display: none
		a
			text-decoration: underline
	h3
		margin: 0
		color: #000
		position: relative
		width: 100%
		button
			text-align: left
			padding: 0
			background-color: transparent
			border: none
			font-size: 18px
			font-family: common.$switzer
			width: 100%
			position: relative
			cursor: pointer
			color: #000
			padding-right: 20px
			span
				width: 20px
				height: 20px
				position: absolute
				top: 50%
				right: 0
				display: block
				transform: translateY(-50%)
				overflow: hidden
				margin-left: 10px
				&::after
					position: absolute
					content: ''
					width: 100%
					height: 2px
					background-color: #000
					top: 50%
					left: 0
				&::before
					position: absolute
					content: ''
					width: 100%
					height: 2px
					background-color: #000
					top: 50%
					left: 0
					transform: rotate(90deg)
					transition: transform .35s ease

@use 'common'

.wrapper
    width: 100%
    color: #fff
    background-color: common.$transactionGreen
    padding: 10px 20px
    z-index: 102
    //position: absolute
    top: 0
    left: 0
    width: 100%
    //height: 30px
    text-align: center
    font-size: 14px
    display: flex
    align-items: center
    justify-content: center
    position: relative
    a
        text-decoration: underline
    @media(max-width: 768px)
        font-size: 12px
        padding: 5px

@use 'common'

.wrapper
    position: fixed
    display: flex
    justify-content: center
    align-items: center
    width: 100%
    height: 100vh
    z-index: 101
    opacity: 0
    pointer-events: none
    transition: opacity .35s ease
    &::after
        content: ''
        position: fixed
        top: 0
        left: 0
        width: 100%
        height: 100%
        background-color: rgba(0, 0, 0, .7)
    &.visible
        opacity: 1
        pointer-events: auto

.inner
    background-color: #fff
    border: 1px solid #000
    border-radius: 5px
    padding: 20px
    box-sizing: border-box
    color: #000
    min-width: 290px
    position: relative
    z-index: 1
    max-width: 700px
    @media(max-width: 768px)
        max-width: 300px

.headline
    padding: 0
    margin: 0 0 .5em 0
    font-size: 24px

.close
    width: 15px
    height: 15px
    padding: 0
    border: none
    border-radius: 0
    background-color: transparent
    cursor: pointer
    top: 10px
    right: 10px
    position: absolute
    color: #000
